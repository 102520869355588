import React, { useState } from 'react';
import { Menu, Container, Image, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom'
import Logo from '../../Assets/logo.png'

const NavigationBar = () => {
  return (
    <Menu borderless stackable>
      <Container>
        <Menu.Item  style={{justifyContent: 'center'}}  as={Link} to="/">
          <div>
            <div><Image src={Logo} style={{height: 40}}/></div>
            <div style={{textAlign: 'center', backgroundColor: '#cc6600', color: 'white', padding: 2, fontSize: 13}}><b>BLOG</b></div>
          </div>
        </Menu.Item>
        <Menu.Menu position="right" stackable>
          <Menu.Item name="Home" style={{justifyContent: 'center'}} as={Link} to='/'/>
          <Menu.Item name="Blog" style={{justifyContent: 'center'}} as={Link} to='/blog'/>
          <Menu.Item name="Contact" style={{justifyContent: 'center'}} as={Link} to='/contact'/>
        </Menu.Menu>
      </Container>
    </Menu>
  );
};

export default NavigationBar;
