import {useEffect, Suspense, lazy} from 'react'
import { Loader } from 'semantic-ui-react'
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom"
import Layout from './Components/Layout/Main'
import './App.css';

const Home = lazy(() => import('./Pages/Home'))
const BlogRead = lazy(() => import('./Pages/BlogRead'))
const BlogList = lazy(() => import('./Pages/BlogList'))
const Contact = lazy(() => import('./Pages/Contact'))

const PageNotFound = () => <div style={{padding: '5%', minHeight: '40vh'}}><h1>Page Not Found</h1></div>

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

function App() {
  return (
      <Router>
        <Suspense fallback={<Loader active inline style={{margin: 10}}/>}>
          <ScrollToTop />
          <Layout>
            <Routes>
               <Route exact path='/' element={<Home/>}></Route>
               <Route exact path='/blog' element={<BlogList/>}></Route>
               <Route exact path='/blog/:slug' element={<BlogRead/>}></Route>
               <Route exact path='/blog/category/:category' element={<BlogList/>}></Route>
               <Route exact path='/contact' element={<Contact/>}></Route>
               <Route exact path='*' element={<PageNotFound/>}></Route>
            </Routes>
          </Layout>
        </Suspense>
      </Router>
  );
}

export default App;
