import React from 'react';
import { Container, Grid, Menu, Segment, Header, Image, Card, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom'
import Logo from '../../Assets/logo.png'

const Footer = () => {
  return (
    <Segment vertical style={{ padding: '5em 0em', backgroundColor: '#f6f6f6', marginTop: 50 }}>
      <Container textAlign='center'>
        <Image src={Logo} style={{height:50, margin: 'auto'}}/>
        <p style={{color: '#888'}}>Well curated learning materials for you to learn.</p>
        <Menu secondary fluid widths={10}>
          <Menu.Item as={Link} to="/">
            Home
          </Menu.Item>
          <Menu.Item as={Link} to="/blog">
            Blog
          </Menu.Item>
          <Menu.Item as={Link} to="/contact">
            Contact
          </Menu.Item>
        </Menu>
        <br/>
        <br/>
        <div>
          Copyright © {new Date().getFullYear()} Corpola Tech Pvt. Ltd. - All rights reserved.
        </div>
      </Container>
    </Segment>
  );
};

const Footera = () => {
  return (
    <>
      <Grid columns={4}>
        <Grid.Row>
          <Grid.Column mobile={8} tablet={4} computer={4}>
            <div><h3>Contact Us</h3></div>
            <div>contact@mayacaps.com</div>
          </Grid.Column>
          <Grid.Column mobile={8} tablet={4} computer={4}>
            <div><h3>Main Pages</h3></div>
            <div><Link to="/">Home</Link></div>
            <div><Link to="/store">Store</Link></div>
            <div><Link to="/brands">Brands</Link></div>
            <div><Link to="/checkout">My Cart</Link></div>
          </Grid.Column>
          <Grid.Column mobile={8} tablet={4} computer={4}>
            <div><h3>Other Pages</h3></div>
            <div><Link to="/about">About</Link></div>
            <div><Link to="/contact">Contact</Link></div>
            <div><Link to="/faqs">FAQs</Link></div>
            <div><Link to="/terms-and-conditions">Terms & Conditions</Link></div>
            <div><Link to="/privacy-policy">Privacy Policy</Link></div>
          </Grid.Column>
          <Grid.Column mobile={8} tablet={4} computer={4}>
            <div><h3>Social Media</h3></div>
            <div><Link target="_blank" to="https://www.facebook.com/profile.php?id=100090263838491">Facebook ⇱</Link></div>
            <div><Link target="_blank" to="https://www.instagram.com/mayacapsktm/">Instagram ⇱</Link></div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid>
      <Grid.Row>
        <Grid.Column>
          <hr/>
        </Grid.Column>
      </Grid.Row>
      </Grid>
    </>
  )
}

export default Footer
