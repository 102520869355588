import NavigaitionBar from './NavigaitionBar'
import Footer from './Footer'

const Layout = ({children}) => {
  return(
    <div>
      <NavigaitionBar/>
      <div style={{minHeight: '60vh'}}>{children}</div>
      <Footer />
    </div>
  )
}

export default Layout
